<template>
  <div class="ownerDecorationGuidanceList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParams.title"></v-input>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select2 label="所属公司" v-model="searchParams.regionId" v-bind="tenantParams"></v-select2>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="创建时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status !== 0" text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 1" text="关闭" type="text" permission="on-off" @click="changeState(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 0" text="开启" type="text" permission="on-off" @click="changeState(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  uploadGuideURL
} from './api'
import { statusOps, statusMap } from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'OwnerDecorationGuidanceList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps,
      communityParams,
      tenantParams,
      searchParams: {
        title: '',
        communityId: '',
        regionId: '',
        status: undefined,
        startTime: '',
        endTime: ''
      },
      headers: [
        {
          prop: 'title',
          label: '标题'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'statusText',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'updateUser',
          label: '操作人'
        },
        {
          prop: 'updateTime',
          label: '最后操作时间'
        }
      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'ownerDecorationGuidanceForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'ownerDecorationGuidanceForm',
        query: {
          id: row.id
        }
      })
    },
    async changeState (row) {
      let msg = row.status === 1 ? '是否确认关闭' : '是否确认开启'
      let isOk = await this.$confirm(msg)
      let data = {
        status: row.status,
        communityId: row.communityId
      }
      isOk && this.$axios.put(`${uploadGuideURL}/${row.id}`, this.$qs.stringify(data)).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    }
  }
}
</script>
